<template>
    <div>
        <div class="fileuplaod" v-show="fileuplaod" @click="importEx">
            <img src="../../../assets/images/credit/fileupload.png">
        </div>
        <div style="position:fixed;right:20px;top:130px;">
            <el-button size="small" type="primary" @click="save">保存</el-button>
            <el-button size="small" @click="fileuplaod = true">导入数据<i class="el-icon-upload el-icon--right"></i></el-button>
        </div>
         <div class="dupont-msg-title">
            <div>
                <div class="iconImg">
                    <img src="../../../assets/images/credit/basic/基本信息@2x.png"/>
                </div>
                <div class="text">企业基础信息</div>
            </div>
            <div>
            </div>
        </div>

        <ent-msg ref="entmsg"></ent-msg>

        <div class="dupont-msg-title">
            <div>
                <div class="iconImg">
                    <img src="../../../assets/images/credit/basic/基本信息@2x.png"/>
                </div>
                <div class="text">人力资源信息</div>
            </div>
            <div>
            </div>
        </div>

        <rl-res ref="rlres"></rl-res>

        <div class="dupont-msg-title">
            <div>
                <div class="iconImg">
                    <img src="../../../assets/images/credit/basic/基本信息@2x.png"/>
                </div>
                <div class="text">企业运营信息</div>
            </div>
            <div>
            </div>
        </div>

        <ent-operates ref="entoperates"></ent-operates>
    </div>
</template>
<script> 
import entMsg from '../component/table-entMsg-pj'
import rlRes from '../component/table-rlres-pj'
import entOperates from '../component/table-entoperateMsg-pj'
export default {
    name:'creditModelDupontPj',
    components:{entMsg,rlRes,entOperates},
    data(){
        return {
            fileuplaod:false,
        }
    },
    methods:{
         importEx(){
            this.fileuplaod = false
            const loading = this.$loading({
                lock: true,
                text: '数据导入中',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });  
            setTimeout(() => { 
                loading.close();
                this.$refs.entmsg.importData()
                this.$refs.rlres.importData()
                this.$refs.entoperates.importData()
            }, 2000);
        },
         save(){ 
             this.$message({
                message: this.$store.state.saveText,
                type: 'success'
            });
            var entName = localStorage.getItem('dupontEntName')
            var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))
            entData.entMsg = JSON.stringify(this.$refs.entmsg.form)
            entData.rlres = JSON.stringify(this.$refs.rlres.form)
            entData.entoperates = JSON.stringify(this.$refs.entoperates.form)

            // localStorage.setItem('entMsg',JSON.stringify(this.$refs.entmsg.form))
            // localStorage.setItem('rlres',JSON.stringify(this.$refs.rlres.form))
            localStorage.setItem('dupontEntData'+entName,JSON.stringify(entData))
         }
    }
}
</script>
<style lang="less" scoped>
@import url('../../../assets/css/table.css');

.fileuplaod{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    cursor:pointer;
    z-index:1;
}
    .dupont-msg-title{
       display: flex;
       line-height: 30px;
       justify-content: space-between;
       margin-top:20px;
       >div{
           display: flex;
           line-height: 30px;
           >.iconImg{
               width: 30px;
               height: 30px;
               display: flex;
               align-items: center;
               // justify-content: center;
               >img{
                   width: 20px;
               }
           }
           >.text{
               font-size: 18px;
               font-weight: bold;
               color: #2DB7F5;
           }
       }
       span{
           font-size: 12px;
           font-family: Source Han Sans SC;
           font-weight: 400;
           color: #8A8A98; 
       }
   }
   .tableBox{
       width: 100%;
       overflow-x: auto;
   }
</style>