<template>
  <div>
    <div class="title">
      <div>从业人员情况表</div>
      <div></div>
    </div>
    <div class="tableBox">
      <table class="re_search_detail_table">
        <thead>
          <tr>
            <td width="20%;" colspan="2" class="detailtitle"></td>
            <td width="20%;" colspan="2" class="detailtitle">2017年(前3年)</td>
            <td width="20%;" colspan="2" class="detailtitle">2018年(前2年)</td>
            <td width="20%;" colspan="2" class="detailtitle">2019年(上1年)</td>
            <td width="20%;" colspan="1" class="detailtitle">2020年(12月)</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width="20%;" colspan="2">人员总数(人)</td>
            <td width="20%;" colspan="2"><input type="text" v-model="form.cyry.rszs2017"/></td>
            <td width="20%;" colspan="2"><input type="text" v-model="form.cyry.rszs2018"/></td>
            <td width="20%;" colspan="2"><input type="text" v-model="form.cyry.rszs2019"/></td>
            <td width="20%;" colspan="1"><input type="text" v-model="form.cyry.rszs2020"/></td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <td width="100%;" colspan="9" class="detailtitle">
              目前人员构成情况
            </td>
          </tr>
          <tr>
            <td width="15%;" rowspan="2" class="detailtitle">总数</td>
            <td width="35%;" colspan="4" class="detailtitle">学历构成</td>
            <td width="35%;" colspan="3" class="detailtitle">岗位构成</td>
            <td width="15%;" rowspan="2" class="detailtitle">
              社会保障参保人数
            </td>
          </tr>
          <tr>
            <td width="8.75%;" class="detailtitle">大专以下</td>
            <td width="8.75%;" class="detailtitle">大专</td>
            <td width="8.75%;" class="detailtitle">本科</td>
            <td width="8.75%;" class="detailtitle">研究生及以上</td>
            <td width="11.6%;" class="detailtitle">管理人员</td>
            <td width="11.6%;" class="detailtitle">作业人员</td>
            <td width="11.6%;" class="detailtitle">市场人员</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width="15%;"><input type="text" v-model="form.cyry.zs"/></td>
            <td width="8.75%;"><input type="text" v-model="form.cyry.dzyx"/></td>
            <td width="8.75%;"><input type="text" v-model="form.cyry.dz"/></td>
            <td width="8.75%;"><input type="text" v-model="form.cyry.bk"/></td>
            <td width="8.75%;"><input type="text" v-model="form.cyry.yjs"/></td>
            <td width="11.6%;"><input type="text" v-model="form.cyry.gl"/></td>
            <td width="11.6%;"><input type="text" v-model="form.cyry.zy" /></td>
            <td width="11.6%;"><input type="text" v-model="form.cyry.sc" /></td>
            <td width="15%;"><input type="text" v-model="form.cyry.cb" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="title">
      <div>核心管理人员情况表</div>
      <div></div>
    </div>
    <div class="tableBox">
      <table class="re_search_detail_table">
        <thead>
          <tr>
            <td width="20%;" colspan="1" class="detailtitle"></td>
            <td width="20%;" colspan="2" class="detailtitle">2017年(前3年)</td>
            <td width="20%;" colspan="2" class="detailtitle">2018年(前2年)</td>
            <td width="20%;" colspan="2" class="detailtitle">2019年(上1年)</td>
            <td width="20%;" colspan="1" class="detailtitle">2020年(12月)</td>
          </tr> 
        </thead>
        <tbody>
          <tr>
            <td width="20%;" colspan="1">人员总数(人)</td>
            <td width="20%;" colspan="2"><input type="text" v-model="form.hxgl.rszs2017"/></td>
            <td width="20%;" colspan="2"><input type="text" v-model="form.hxgl.rszs2018"/></td>
            <td width="20%;" colspan="2"><input type="text" v-model="form.hxgl.rszs2019"/></td>
            <td width="20%;" colspan="1"><input type="text" v-model="form.hxgl.rszs2020"/></td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <td width="100%;" colspan="8" class="detailtitle">
              管理人员基本情况*
            </td>
          </tr>
          <tr>
            <td width="10%;" class="detailtitle">序号</td>
            <td width="10%;" class="detailtitle">姓名</td>
            <td width="5%;" class="detailtitle">性别</td>
            <td width="20%;" class="detailtitle">个人身份证号码</td>
            <td width="15%;" class="detailtitle">现任职务</td>
            <td width="10%;" class="detailtitle">最高学历</td>
            <td width="15%;" class="detailtitle">同业从业年限</td>
            <td width="15%;" class="detailtitle">入职公司时间</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width="10%;"><input type="text" v-model="form.hxgl.xh"/></td>
            <td width="10%;"><input type="text" v-model="form.hxgl.name"/></td>
            <td width="5%;"><input type="text" v-model="form.hxgl.sex"/></td>
            <td width="20%;"><input type="text" v-model="form.hxgl.no"/></td>
            <td width="15%;"><input type="text" v-model="form.hxgl.zw"/></td>
            <td width="10%;"><input type="text" v-model="form.hxgl.xl"/></td>
            <td width="15%;"><input type="text" v-model="form.hxgl.year"/></td>
            <td width="15%;"><input type="text" v-model="form.hxgl.comDate"/></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="title">
      <div>企业实际控制人和管理人基本情况</div>
      <div></div>
    </div>
    <div class="tableBox">
      <table class="re_search_detail_table">
          <tbody>
            <tr> 
              <td width="10%;" class="detailtitle">姓名</td>
              <td width="20%;"><input type="text" v-model="form.jbxx.name"/></td>
              <td width="10%;" class="detailtitle">性别</td>
              <td width="20%;"><input type="text" v-model="form.jbxx.sex"/></td>
              <td width="10%;" class="detailtitle">出生年月</td>
              <td width="30%;"><input type="text" v-model="form.jbxx.date"/></td>
            </tr>
            <tr> 
              <td width="10%;" class="detailtitle">身份证号</td>
              <td width="20%;"><input type="text" v-model="form.jbxx.no"/></td>
              <td width="10%;" class="detailtitle">学历</td>
              <td width="20%;"><input type="text" v-model="form.jbxx.xl"/></td>
              <td width="10%;" class="detailtitle">国籍</td>
              <td width="30%;"><input type="text" v-model="form.jbxx.gj"/></td>
            </tr>
            <tr> 
              <td width="10%;" class="detailtitle">联系电话</td>
              <td width="20%;"><input type="text" v-model="form.jbxx.tel"/></td>
              <td width="10%;" class="detailtitle">手机号码</td>
              <td width="20%;"><input type="text" v-model="form.jbxx.phone"/></td>
              <td width="10%;" class="detailtitle">电子邮件</td>
              <td width="30%;"><input type="text" v-model="form.jbxx.email"/></td>
            </tr>
            <tr> 
              <td width="10%;" class="detailtitle">目前地址</td>
              <td width="50%;" colspan="3"><input type="text" v-model="form.jbxx.naddress"/></td>
              <td width="10%;" class="detailtitle">邮政编码</td>
              <td width="30%;"><input type="text" v-model="form.jbxx.nyzbm"/></td>
            </tr>
            <tr> 
              <td width="10%;" class="detailtitle">户籍地址</td>
              <td width="50%;" colspan="3"><input type="text" v-model="form.jbxx.haddress"/></td>
              <td width="10%;" class="detailtitle">邮政编码</td>
              <td width="30%;"><input type="text" v-model="form.jbxx.hyzbm"/></td>
            </tr>
            <tr> 
              <td width="100%;" colspan="6" class="detailtitle">学习工作履历（从最近年开始填写）</td>
            </tr>
            <tr> 
              <td width="15%;" rowspan="3" class="detailtitle">教育经历*</td>
              <td width="40%;" colspan="2" class="detailtitle">就读学校（高中后）</td>
              <td width="10%;" class="detailtitle">入学时间</td>
              <td width="10%;" class="detailtitle">毕业时间</td>
              <td width="15%;" class="detailtitle">专业及学位</td>
            </tr>
            <tr> 
              <td width="40%;" colspan="2" ><input type="text" v-model="form.jbxx.jyjl.gz1"/></td>
              <td width="10%;" ><input type="text" v-model="form.jbxx.jyjl.date1"/></td>
              <td width="10%;" ><input type="text" v-model="form.jbxx.jyjl.bydate1"/></td>
              <td width="15%;" ><input type="text" v-model="form.jbxx.jyjl.xw1"/></td>
            </tr>
            <tr> 
              <td width="40%;" colspan="2" ><input type="text" v-model="form.jbxx.jyjl.gz2"/></td>
              <td width="10%;" ><input type="text" v-model="form.jbxx.jyjl.date2"/></td>
              <td width="10%;" ><input type="text" v-model="form.jbxx.jyjl.bydate2"/></td>
              <td width="15%;" ><input type="text" v-model="form.jbxx.jyjl.xw2"/></td>
            </tr>
            <tr> 
              <td width="15%;" rowspan="4" class="detailtitle">工作经历*</td>
              <td width="40%;" colspan="2" class="detailtitle">工作单位</td>
              <td width="10%;" class="detailtitle">入职时间</td>
              <td width="10%;" class="detailtitle">离职时间</td>
              <td width="15%;" class="detailtitle">担任职务</td>
            </tr>
            <tr>  
              <td width="40%;" colspan="2" ><input type="text" v-model="form.jbxx.gzjl.dw1"/></td>
              <td width="10%;" ><input type="text" v-model="form.jbxx.gzjl.rzdate1"/></td>
              <td width="10%;" ><input type="text" v-model="form.jbxx.gzjl.lzdate1"/></td>
              <td width="15%;" ><input type="text" v-model="form.jbxx.gzjl.drzw1"/></td>
            </tr>
            <tr>  
              <td width="40%;" colspan="2" ><input type="text" v-model="form.jbxx.gzjl.dw2"/></td>
              <td width="10%;" ><input type="text" v-model="form.jbxx.gzjl.rzdate2"/></td>
              <td width="10%;" ><input type="text" v-model="form.jbxx.gzjl.lzdate2"/></td>
              <td width="15%;" ><input type="text" v-model="form.jbxx.gzjl.drzw2"/></td>
            </tr>
            <tr>  
              <td width="40%;" colspan="2" ><input type="text" v-model="form.jbxx.gzjl.dw3"/></td>
              <td width="10%;" ><input type="text" v-model="form.jbxx.gzjl.rzdate3"/></td>
              <td width="10%;" ><input type="text" v-model="form.jbxx.gzjl.lzdate3"/></td>
              <td width="15%;" ><input type="text" v-model="form.jbxx.gzjl.drzw3"/></td>
            </tr>
            <tr> 
              <td width="10%;" class="detailtitle">主要创新成果</td>
              <td width="90%;" colspan="5" ><input type="text" v-model="form.jbxx.cxcg"/></td>
            </tr>
            <tr> 
              <td width="10%;" class="detailtitle">重要奖惩情况</td>
              <td width="90%;" colspan="5" ><input type="text" v-model="form.jbxx.cfqk"/></td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form:{
        cyry:{
          rszs2017:'',
          rszs2018:'',
          rszs2019:'',
          rszs2020:'',
          zs:'',
          dzyx:'',
          dz:'',
          bk:'',
          yjs:'',
          gl:'',
          zy:'',
          sc:'',
          cb:''
        },
        hxgl:{
          rszs2017:'',
          rszs2018:'',
          rszs2019:'',
          rszs2020:'',
          xh:'',
          name:'',
          sex:'',
          no:'',
          zw:'',
          xl:'',
          year:'',
          comDate:''
        },
        jbxx:{
          name:'',
          sex:'',
          date:'',
          no:'',
          xl:'',
          gj:'',
          tel:'',
          phone:'',
          email:'',
          naddress:'',
          nyzbm:'',
          haddress:'',
          hyzbm:'',
          jyjl:{
            gz1:'',
            gz2:'',
            date1:'',
            date2:'',
            bydate1:'',
            bydate2:'',
            xw1:'',
            xw2:''
          },
          gzjl:{
            dw1:'',
            dw2:'',
            dw3:'',
            rzdate1:'',
            rzdate2:'',
            rzdate3:'',
            lzdate1:'',
            lzdate2:'',
            lzdate3:'',
            drzw1:'',
            drzw2:'',
            drzw3:'',
          },
          cxcg:'',
          cfqk:''
        }
      }
    };
  },
  mounted(){
    var entName = localStorage.getItem('dupontEntName')
    var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))

    var rlres = entData.rlres
    this.form = JSON.parse(rlres)
  },
  methods:{
    importData(){
      this.form = JSON.parse('{"cyry":{"rszs2017":"1000","rszs2018":"10000","rszs2019":"10000","rszs2020":"10000","zs":"1000","dzyx":"100","dz":"100","bk":"100","yjs":"100","gl":"100","zy":"100","sc":"300","cb":"1000"},"hxgl":{"rszs2017":"100","rszs2018":"100","rszs2019":"100","rszs2020":"100","xh":"1","name":"张三","sex":"男","no":"111222333344445555","zw":"开发","xl":"本科","year":"1987","comDate":"1987"},"jbxx":{"name":"张三","sex":"男","date":"1977年1月1日","no":"111222333344445555","xl":"本科","gj":"中国","tel":"1111112","phone":"13111112222","email":"12345@qq.com","naddress":"北京市","nyzbm":"100000","haddress":"北京市","hyzbm":"100000","jyjl":{"gz1":"清华","gz2":"北大","date1":"2000","date2":"2004","bydate1":"2004","bydate2":"2008","xw1":"本科","xw2":"本科"},"gzjl":{"dw1":"北京奥利给有限公司","dw2":"北京给料有限公司","dw3":"北京giaogiao有限个弄死","rzdate1":"2000","rzdate2":"2002","rzdate3":"2003","lzdate1":"2001","lzdate2":"2002","lzdate3":"2004","drzw1":"开发","drzw2":"开发","drzw3":"开发"},"cxcg":"无","cfqk":"无"}}')
    }
  }
};
</script> 
<style lang="less" scoped>
.title {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  > div {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #8a8a98;
    &:nth-child(1) {
      font-size: 16px;
      font-weight: bold;
      line-height: 27px;
      color: #30343d;
    }
  }
}
.tableBox {
  width: 100%;
  overflow-x: auto;
  input,
  textarea {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    text-align: center;
  }
}
</style>