<template>
    <div>
        <div class="tableBox">
            <table class="re_search_detail_table">
          <tbody>
            <tr> 
              <td width="10%;" class="detailtitle">企业名称</td>
              <td width="90%;" colspan="5"><input type="text" v-model="form.entname"></td>
            </tr>
            <tr>  
              <td width="10%;" class="detailtitle">成立日期</td>
              <td width="20%;"><input type="text" v-model="form.createDate"></td>
              <td width="10%;" class="detailtitle">注册地址</td>
              <td width="60%;"  colspan="3"><input type="text" v-model="form.zcdz"></td>
            </tr>
            <tr> 
              <td width="10%;" class="detailtitle">注册资本</td>
              <td width="20%;"><input type="text" v-model="form.zczb"></td>
              <td width="10%;" class="detailtitle">办公地址</td>
              <td width="60%;"  colspan="3"><input type="text" v-model="form.bgdz"></td>
            </tr>
            <tr> 
              <td width="10%;" class="detailtitle">法定代表人</td>
              <td width="20%;"><input type="text" v-model="form.frdb"></td>
              <td width="10%;" class="detailtitle">工商注册号</td>
              <td width="60%;"  colspan="3"><input type="text" v-model="form.gszch"></td>
            </tr>
            <tr> 
              <td width="10%;" class="detailtitle">员工总数</td>
              <td width="20%;"><input type="text" v-model="form.ygzs"></td>
              <td width="10%;" class="detailtitle">税务登记号</td>
              <td width="60%;"  colspan="3"><input type="text" v-model="form.swdjh"></td>
            </tr>
            <tr> 
              <td width="10%;" class="detailtitle">是否高新企业</td>
              <td width="20%;"><el-radio v-model="form.isgxent" label="1">是</el-radio>
                                <el-radio v-model="form.isgxent" label="2">否</el-radio></td>
              <td width="10%;" class="detailtitle">组织机构代码</td>
              <td width="60%;" colspan="3"><input type="text" v-model="form.zzjgdm"></td>
            </tr>
            <tr>  
              <td width="10%;" class="detailtitle">企业性质<br/>
            （每行各选1）
            </td>
              <td width="90%;" colspan="5" style="text-align:left;">
                  <div>
                    <el-radio v-model="form.qyxz1" label="1">上市公司</el-radio>
                    <el-radio v-model="form.qyxz1" label="2">新三板挂牌企业</el-radio>
                    <el-radio v-model="form.qyxz1" label="3">其他股份有限公司</el-radio>
                    <el-radio v-model="form.qyxz1" label="4">有限责任公司</el-radio>
                    <el-radio v-model="form.qyxz1" label="5">合伙制</el-radio>
                    <el-radio v-model="form.qyxz1" label="6">其他</el-radio>
                  </div>
                  <div>
                    <el-radio v-model="form.qyxz2" label="1">国有企业</el-radio>
                    <el-radio v-model="form.qyxz2" label="2">集体企业</el-radio>
                    <el-radio v-model="form.qyxz2" label="3">中外合资（合作）企业</el-radio>
                    <el-radio v-model="form.qyxz2" label="4">民营企业</el-radio>
                    <el-radio v-model="form.qyxz2" label="5">混合所有制</el-radio>
                    <el-radio v-model="form.qyxz2" label="6">其他</el-radio>
                  </div>
              </td>
            </tr>
            <tr> 
              <td width="10%;" class="detailtitle">供应商简介</td>
              <td width="90%;" colspan="5" style="text-align:left;">
                  <div>
                    <el-radio v-model="form.gysjj" label="1">微型企业</el-radio>
                    <el-radio v-model="form.gysjj" label="2">小型企业</el-radio>
                    <el-radio v-model="form.gysjj" label="3">中型企业</el-radio>
                    <el-radio v-model="form.gysjj" label="4">大型企业</el-radio>
                  </div>
              </td>
            </tr>
            <tr> 
              <td width="10%;" class="detailtitle">主营业务</td>
              <td width="90%;" colspan="5" style="text-align:left;">
                  <input type="text" v-model="form.zyyw">
              </td>
            </tr>
            <tr> 
              <td width="10%;" class="detailtitle">经营资质<br/>资格认证</td>
              <td width="90%;" colspan="5" style="text-align:left;">
                  <textarea v-model="form.jyzz"></textarea>
              </td>
            </tr>
            <tr> 
              <td width="10%;" class="detailtitle">联系人</td>
              <td width="20%;">
                  <input type="text" v-model="form.lxr">
              </td>
              <td width="20%;" class="detailtitle">联系人职务</td>
              <td width="20%;">
                  <input type="text" v-model="form.lxrzw">
              </td>
              <td width="10%;" class="detailtitle">联系电话</td>
              <td width="20%;">
                  <input type="text" v-model="form.lxtel">
              </td>
            </tr>
            <tr> 
              <td width="10%;" class="detailtitle">联系传真</td>
              <td width="20%;">
                  <input type="text" v-model="form.lxcz">
              </td>
              <td width="20%;" class="detailtitle">E-mail</td>
              <td width="20%;">
                  <input type="text" v-model="form.email">
              </td>
              <td width="10%;" class="detailtitle">联系手机</td>
              <td width="20%;">
                  <input type="text" v-model="form.lxphone">
              </td>
            </tr>
            <tr> 
              <td width="10%;" class="detailtitle">联系地址</td>
              <td width="60%;" colspan="3">
                  <input type="text" v-model="form.lxaddress">
              </td>
              <td width="10%;" class="detailtitle">邮    编</td>
              <td width="20%;">
                  <input type="text" v-model="form.yb">
              </td>
            </tr>
            <tr> 
              <td width="10%;" class="detailtitle">成立的背景及<br/>经营发展历程</td>
              <td width="90%;" colspan="5" style="text-align:left;">
                  <textarea v-model="form.bj"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            form:{
              entname:'',
              createDate:'',
              zcdz:'',
              zczb:'',
              bgdz:'',
              frdb:'',
              gszch:'',
              ygzs:'',
              swdjh:'',
              isgxent:'',
              zzjgdm:'',
              qyxz1:'',
              qyxz2:'',
              gysjj:'',
              zyyw:'',
              jyzz:'',
              lxr:'',
              lxrzw:'',
              lxtel:'',
              lxcz:'',
              email:'',
              lxphone:'',
              lxaddress:'',
              yb:'',
              bj:'',
            }
        }
    },
    mounted(){
      var entName = localStorage.getItem('dupontEntName')
      var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))

      var entMsg = entData.entMsg
      this.form = JSON.parse(entMsg)
    },
    methods:{
      importData(){
        this.form = JSON.parse('{"entname":"","createDate":"1987-01-01","zcdz":"1987年09月15日","zczb":"4,030,813.182","bgdz":"深圳市龙岗区坂田华为总部办公楼","frdb":"赵明路","gszch":"440301103097413","ygzs":"10000","swdjh":"914403001922038216","isgxent":"1","zzjgdm":"192203821","qyxz1":"1","qyxz2":"","gysjj":"4","zyyw":"程控交换机、传输设备、数据通信设备、宽带多媒体设备、电源、无线通信设备、微电子产品、软件、系统集成工程、计算机及配套设备、终端设备及相关通信信息产品、数据中心机房基础设施及配套产品","jyzz":"宽带多媒体设备、电源、无线通信设备、微电子产品、软件、系统集成工程、计算机及配套设备、终端设备及相关通信信息产品、数据中心机房基础设施及配套产品","lxr":"张三","lxrzw":"员工","lxtel":"1234567","lxcz":"12345","email":"12345@qq.com","lxphone":"13111112222","lxaddress":"北京市","yb":"","bj":"华为技术有限公司，成立于1987年，总部位于广东省深圳市龙岗区。 [1] 华为是全球领先的信息与通信技术（ICT）解决方案供应商，专注于ICT领域，坚持稳健经营、持续创新、开放合作，在电信运营商、企业、终端和云计算等领域构筑了端到端的解决方案优势，为运营商客户、企业客户和消费者提供有竞争力的ICT解决方案、产品和服务，并致力于实现未来信息社会、构建更美好的全联接世界。2013年，华为首超全球第一大电信设备商爱立信，排名《财富》世界500强第315位。华为的产品和解决方案已经应用于全球170多个国家，服务全球运营商50强中的45家及全球1/3的人口。"}')
        this.form.entname = localStorage.getItem('dupontEntName')
      }
    }
}
</script> 
<style lang="less" scoped>
.tableBox{
    width:100%;
    overflow-x: auto;
    input,textarea{
        width:100%;
        height: 100%;
        border: none;
        outline: none;
        text-align: center;
    }
}
</style>